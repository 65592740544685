import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKINS_PORT}${process.env.VUE_APP_API_PATH}/checkins`;
const urlBase = `${api}/eventos`;
const formDataType = {
    headers: {
        'Content-Type': 'multipart/form-data',
    },
};

export default {

    obterTodos(filtros) {
        return axiosJwt.get(`${urlBase}?codigo=${filtros.codigo ?? ''}&codigoInterno=${filtros.codigoInterno ?? ''}&codigoExterno=${filtros.codigoExterno ?? ''}&nome=${filtros.nome ?? ''}&descricao=${filtros.descricao ?? ''}&dataInicio=${filtros.dataInicio ?? ''}&dataFim=${filtros.dataFim ?? ''}`);
    },

    inserir(evento) {
        return axiosJwt.post(`${urlBase}/inserir`, evento);
    },

    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },

    obterEventosEvento(eventoId) {
        return axiosJwt.get(`${urlBase}/${eventoId}/eventos`);
    },

    obterCheckinsEvento(eventoId) {
        return axiosJwt.get(`${urlBase}/${eventoId}/checkins`);
    },
    obterListaAgenda(codEvento) {
        return axiosJwt.get(`${urlBase}/${codEvento}/agendas`);
    },

    gerarAtandimento(participantesCheckins, eventoid) {
        return axiosJwt.post(`${api}/participantes/evento/${eventoid}/geraratandimento`, participantesCheckins);
    },

    ativar(eventoId) {
        return axiosJwt.patch(`${urlBase}/${eventoId}/ativar`);
    },

    desativar(eventoId) {
        return axiosJwt.patch(`${urlBase}/${eventoId}/desativar`);
    },

    atualizar(data) {
        return axiosJwt.put(`${urlBase}/atualizar`, data);
    },

    obterAtendimentoDetalhePorEventoId(eventoId) {
        return axiosJwt.get(`${api}/eventos/${eventoId}/atendimentosdetalhes`);
    },

    obterOrientacaoEventoPaiPraFilho(eventoId) {
        return axiosJwt.get(`${api}/eventos/${eventoId}/atendimentosdetalhes/orientacaoTecnica`);
    },

    atualizarAtendimentoDetalhe(data, eventoId) {
        return axiosJwt.put(`${api}/eventos/${eventoId}/atendimentosdetalhes/atualizar`, data);
    },

    obterOpcoes(eventoId) {
        return axiosJwt.get(`${api}/eventos/${eventoId}/atendimentosdetalhes/opcoes`);
    },

    obterParceiroPorCpf(cpf, eventoId) {
        return axiosJwt.get(`${api}/eventos/${eventoId}/atendimentosdetalhes/responsavel/${cpf}`);
    },

    obterConfigsIntegracaoEvento(eventoId) {
        return axiosJwt.get(`${urlBase}/${eventoId}/configintegracao`);
    },

    atualizarConfigsIntegracaoEvento(eventoId, data) {
        return axiosJwt.put(`${urlBase}/${eventoId}/configintegracao/atualizar`, data);
    },

    obterDashboard(eventoId) {
        return axiosJwt.get(`${api}/eventos/${eventoId}/dashboard`);
    },

    obterContasEventos() {
        return axiosJwt.get(`${api}/eventos/contas`);
    },

    gerarQrCode(eventoId) {
        return axiosJwt.get(`${api}/eventos/${eventoId}/qrcode`);
    },

    atualizarImagemCredenciamento(imagemCredenciamento) {
        const formData = new FormData();
        formData.append('eventoId', imagemCredenciamento.eventoId);
        formData.append('imagemCredenciamento', imagemCredenciamento.imagemCredenciamento);
        return axiosJwt.put(`${api}/eventos/${imagemCredenciamento.eventoId}/imagemcredenciamento`, formData, formDataType);
    },

    downloadImagemCredenciamento(eventoId) {
        return axiosJwt.get(`${api}/eventos/${eventoId}/imagemcredenciamento`, {
            responseType: 'blob',
        });
    },

    excluirImagemCredenciamento(eventoId) {
        return axiosJwt.delete(`${api}/eventos/${eventoId}/imagemcredenciamento`);
    },

    pesquisarEventoInterno(codEvento) {
        return axiosJwt.get(`${api}/eventos/pesquisareventointerno/${codEvento}`);
    },
    gerarQrCodeConfiguracao(listagemEventosQrCode) {
        return axiosJwt.post(`${api}/eventos/gerarlistaeventoqrcode`, listagemEventosQrCode, {
            headers: {
                'Content-Type': 'application/json',
            }, 
            responseType: 'blob',
        });
    }
};